<template>
  <div>
    <HeaderWrp />
    <div class="my_container">
      <div class="page__breadcrumbs">
        <div class="links">
          <div class="options" @click="$router.go(-1)">
            <img
              src="/img/success-forward.svg"
              alt="arrow-img"
              class="arrow-img"
            />
            <span>{{ $t("breadCrumbs.back") }}</span>
          </div>
          <router-link to="/">{{ $t("course.btnPath.home") }}</router-link>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="arr"
          >
            <path
              d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z"
              fill="#000001"
            />
            <path
              d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z"
              fill="#000001"
            />
          </svg>
          <router-link to="/category">{{
            $t("course.btnPath.category")
          }}</router-link>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="arr"
          >
            <path
              d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z"
              fill="#000001"
            />
            <path
              d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z"
              fill="#000001"
            />
          </svg>
          <router-link class="curr" to="/about">{{ product.name }}</router-link>
        </div>
        <div class="course__row">
          <div class="left">
            <div class="course-vantages">
              <h2 class="course-vantages__title">
                {{ $t("course.skillTitle.text") }}
                <span>{{ $t("course.skillTitle.color") }}</span>
              </h2>
              <div class="course-vantages__wrap">
                <div
                  class="vantage-item"
                  v-for="item in product.course_skill_will_get"
                  :key="item.id"
                >
                  <div class="vantage-item__icon">
                    <svg
                      width="45"
                      height="45"
                      viewBox="0 0 45 45"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 9L38 22.5L21 36"
                        stroke="#0066FF"
                        stroke-width="3"
                      />
                      <path
                        d="M8 9L25 22.5L8 36"
                        stroke="#0066FF"
                        stroke-width="3"
                      />
                    </svg>
                  </div>
                  <div class="vantage-item__descr" v-html="item.name"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="right">
            <div class="course-title">
              <h1>{{ product.name }}</h1>
              <div v-if="product.discount" class="course-title__discount">
                <span>{{ product.discount }}%</span>
              </div>
            </div>
            <div class="course-preview" @click="showPreviewVideo(0)">
              <div
                class="course-preview__label discount"
                v-if="product.discount"
              >
                <span>{{ product.discount }}%</span>
              </div>
              <div class="course-preview__label" v-if="product.is_new">
                <span>New</span>
              </div>
              <div class="course-preview__image">
                <img
                  :src="product.image_thumbnail"
                  alt="preview-image"
                  v-if="product.image_thumbnail"
                />
              </div>
              <span class="course-preview__descr">
                <div class="button-play">
                  <svg
                    width="33"
                    height="38"
                    viewBox="0 0 33 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="product.image_thumbnail"
                  >
                    <path
                      d="M31.5 20.732C32.8333 19.9622 32.8333 18.0378 31.5 17.268L3.75 1.24648C2.41666 0.476677 0.75 1.43893 0.75 2.97853V35.0215C0.75 36.5611 2.41667 37.5233 3.75 36.7535L31.5 20.732Z"
                      fill="white"
                    />
                  </svg>
                  <loaderIcon color="#930685bf" v-else />
                </div>
                <span class="description">{{ $t("course.preview") }}</span>
              </span>
            </div>
            <div class="course-props">
              <div class="course-props__tags">
                <a v-for="tag in product.brand" :key="tag.id">{{
                  tag.title
                }}</a>
                <div class="duration">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                      fill="#000001"
                    />
                    <path d="M8 9C8 9 8.002 5.25 8 4V9ZM8 9H13Z" fill="white" />
                    <path
                      d="M8 9H13M8 9C8 9 8.002 5.25 8 4V9Z"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span>{{ product.time }}</span>
                </div>
              </div>
            </div>
            <div class="course-prices">
              <div class="course-prices__old" v-if="product.discount">
                {{ product.price }} &#8364;
              </div>
              <div class="course-prices__curr" v-if="priceDiscount">
                {{ priceDiscount }} &#8364;
              </div>
              <div v-else>
                <loaderIcon />
              </div>
              <div class="course-prices__buttons">
                <a
                  v-if="isLogin"
                  class="buy"
                  :class="{ disabled: !isAvailable }"
                  @click="buyCourse"
                >
                  {{ $t("course.buy") }}
                </a>

                <router-link v-else class="buy" :to="{ name: 'login' }">
                  {{ $t("course.buy") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-container">
        <div class="course-contents">
          <div class="course-contents__header">
            <h2>
              {{ $t("course.courseTitle.text") }}
              <span>{{ $t("course.courseTitle.color") }}</span>
            </h2>
            <div class="contents-items">
              <div class="item">
                <div v-if="product.course_content" class="num">
                  {{ product.total_theoretical_video }}
                </div>
                <span>{{ $t("course.chapters") }}</span>
              </div>
              <div class="item">
                <div class="num">
                  {{ product.total_practical_video }}
                </div>
                <span>{{ $t("course.modules") }}</span>
              </div>
            </div>
          </div>

          <div class="course-contents__accordions">
            <div
              class="accordion-item"
              v-for="accordItem in product.course_content"
              :key="accordItem.id"
              :class="{ active: accordItem.status }"
            >
              <div
                class="title"
                @click="accordItem.status = !accordItem.status"
              >
                {{ accordItem.name }}
                <div class="btn"></div>
              </div>

              <div class="description" v-html="accordItem.description"></div>
            </div>
          </div>
        </div>

        <div class="course-about">
          <div
            class="course-about__item"
            v-for="item in product.courses_property"
            :key="item.id"
          >
            <div class="title">{{ item.title }}</div>
            <div class="description" v-html="item.description"></div>
          </div>
        </div>

        <div class="course-details">
          <div class="course-details__image">
            <img
              :src="
                product.image_for_property
                  ? product.image_for_property
                  : '/img/course-about.jpg'
              "
              alt="image"
            />
          </div>
          <div
            class="course-details__descr"
            v-html="product.course_description"
          ></div>
        </div>

        <div class="course-cost">
          <div class="course-cost__title">
            <strong>{{ $t("course.priceTitle") }}</strong>
            <h3>{{ product.name }}</h3>
          </div>
          <div class="course-prices">
            <div class="course-prices__old" v-if="product.discount">
              {{ product.price }} &#8364;
            </div>
            <div class="course-prices__curr" v-if="priceDiscount">
              {{ priceDiscount }} &#8364;
            </div>
            <div v-else>
              <loaderIcon />
            </div>
            <div class="course-prices__buttons">
              <a
                v-if="isLogin"
                class="buy"
                :class="{ disabled: !isAvailable }"
                @click="buyCourse"
              >
                {{ $t("course.buy") }}
              </a>

              <router-link v-else class="buy" :to="{ name: 'login' }">
                {{ $t("course.buy") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="buy__course__wrp"
      :class="{ active: showModalBuyCourse }"
      @click="closeModalBuyCurse"
    >
      <div class="buy__course">
        <div class="top">
          <strong>{{ $t("course.modalBuyCourse.title") }}</strong>
          <div class="close__btn">
            <svg
              class="cls"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.40294 7.99965L0.503906 14.8987L1.10379 15.4986L8.00282 8.59953L14.9019 15.4986L15.5017 14.8987L8.60271 7.99965L15.501 1.10131L14.9012 0.501431L8.00282 7.39976L1.10449 0.501431L0.504607 1.10131L7.40294 7.99965Z"
                fill="white"
              ></path>
            </svg>
          </div>
        </div>
        <!-- paymentSystem -->
        <!-- https://paynet.md/acquiring/setecom -->
        <!-- paymethods !!!! -->
        <form
          class="buyForm"
          ref="formByCourse"
          method="POST"
          action="https://paynet.md/acquiring/setecom"
          enctype="application/json"
        >
          <template v-if="!spiner">
            <div class="inp__row">
              <input
                ref="PayModalName"
                type="text"
                :placeholder="$t('course.modalBuyCourse.name')"
                v-model="modalFormaData.name"
              />
              <input
                ref="PayModalPhone"
                type="text"
                :placeholder="$t('course.modalBuyCourse.phone')"
                v-model="modalFormaData.phone"
              />
            </div>
            <strong>{{ $t("course.modalBuyCourse.modeBuy") }}</strong>
            <select v-model="modalFormaData.select">
              <option>{{ $t("course.modalBuyCourse.buy") }}</option>
            </select>
            <div class="custom_checkbox" v-if="cashback">
              <input
                class="custom-checkbox"
                type="checkbox"
                id="color-1"
                name="color-1"
                v-model="useCashback"
              />
              <label for="color-1">
                <strong
                  >Use Bonuses
                  <span>({{ cashback }}) {{ currency }}</span></strong
                >
              </label>
            </div>
            <template v-if="searchPromoCodesThisProduct.length">
              <strong>
                Promo Code
                <template v-if="discount">
                  <span class="promo">{{ `: discount: ${discount}% ` }}</span>
                  <span class="promo_green"> = {{ priceDiscount + "€" }}</span>
                </template>
              </strong>
              <div class="inp__row">
                <input class="promocode" type="text" v-model.trim="promoCode" />
              </div>
            </template>

            <div class="btm">
              <div class="policy_wrapp">
                <label>
                  <input type="checkbox" v-model="modalFormaData.accept" />
                  <span class="custom-checkbox"></span>
                  <span>
                    <a
                      target="_blank"
                      :href="
                        checkCountryRu
                          ? `/policy_ru/policy_ru.pdf`
                          : !checkCounryRo
                          ? `/policy_md/policy_${lang}.pdf`
                          : '/policy_ro/policy_ro.pdf'
                      "
                    >
                      {{ $t("course.modalBuyCourse.conditions") }}
                    </a>
                  </span>
                </label>
                <label>
                  <input type="checkbox" v-model="modalFormaData.accept2" />
                  <span class="custom-checkbox"></span>
                  <span>
                    <a
                      target="_blank"
                      :href="
                        checkCountryRu
                          ? `/terms_ru/terms_ru.pdf`
                          : !checkCounryRo
                          ? `/reglament_md/reglament-${lang}.pdf`
                          : `/terms_ro/terms_ro.pdf`
                      "
                    >
                      {{ $t("course.modalBuyCourse.terms") }}
                    </a>
                  </span>
                </label>
                <label>
                  <input type="checkbox" v-model="modalFormaData.accept3" />
                  <span class="custom-checkbox"></span>
                  <span>
                    <a target="_blank" :href="`/payment/payment_${lang}.pdf`">
                      {{ $t("course.modalBuyCourse.payment") }}
                    </a>
                  </span>
                </label>
              </div>
              <button
                @click="
                  sendData();
                  googleEventCourseInitiated();
                "
                type="button"
              >
                {{ $t("course.modalBuyCourse.modalBtnBuy") }}
              </button>
              <!-- <button @click="checkCounryRo ? sendDataRo() : sendData()" type="button">{{ selectLang.modalBuyCourse.modalBtnBuy }}</button> -->
            </div>
          </template>
          <img
            v-else
            class="spiner_course"
            src="/img/spiner.gif"
            alt="spiner"
          />
          <template>
            <div v-show="false" ref="formWrapp"></div>
          </template>
        </form>
      </div>
    </div>
    <CoolLightBox
      :items="courses"
      :index="courses[0].show"
      @close="courses[0].show = null"
    >
    </CoolLightBox>
    <FooterApp />
  </div>
</template>

<script>
import LangRu from "@/lang/Course/ru";
import LangRo from "@/lang/Course/ro";
import LangEng from "@/lang/Course/eng";

import HeaderWrp from "@/components/HeaderWrp";
import FooterApp from "@/components/FooterApp";
import ContentInfoDropdown from "@/components/ContentInfoDropdown";
import CoolLightBox from "vue-cool-lightbox";
import { mapGetters } from "vuex";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

const domain = process.env.VUE_APP_BASE_API;
const crypto = require("crypto");
const utf8 = require("utf8");

import loaderIcon from "@/components/icons/loaderIcon.vue";

export default {
  mixins: [LangRu, LangRo, LangEng],
  components: {
    HeaderWrp,
    FooterApp,
    ContentInfoDropdown,
    CoolLightBox,
    loaderIcon,
  },
  data: () => ({
    product: {},
    spiner: false,
    course: null,
    showModalBuyCourse: false,
    modalFormaData: {
      name: "",
      phone: "",
      select: "Card bancar",
      accept: false,
      accept2: false,
      accept3: false,
    },
    courses: [
      {
        title: "",
        description: "",
        src: "",
        show: null,
      },
    ],
    promoCode: "",
    responcePromoCodes: [],
    discount: 0,
    useCashback: false,
    cashback: 0,
    newCashback: 0,
    spentBonuses: 0,
  }),
  metaInfo() {
    return {
      title: this.product.title_page,
      meta: [
        {
          vmid: "title",
          name: "title",
          content: this.product.meta_title,
        },
        {
          vmid: "description",
          name: "description",
          content: this.product.meta_description,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCourse: "courses/getOne",
      isLogin: "user/isLogin",
      lang: "languages/getLang",
      user: "user/user",
    }),
    selectLang() {
      if (this.lang == "ro") {
        return this.langRo;
      } else if (this.lang == "ru") {
        return this.langRu;
      }
      return this.langEng;
    },
    validatePhone() {
      if (this.modalFormaData.phone.length <= 6) {
        return false;
      }
      return true;
    },
    validateName() {
      if (this.modalFormaData.name.length < 2) {
        return false;
      }
      return true;
    },
    checkCity() {
      if (this.user.profile?.profile) {
        return this.user.profile?.profile.city == "Moldova, Republic of";
      }
      return false;
    },
    checkCounryRo() {
      if (this.user.profile?.profile) {
        return this.user.profile?.profile.city == "Romania";
      }
      return false;
    },
    checkCountryRu() {
      if (this.user.profile?.profile) {
        return this.user.profile?.profile.city == "Russian Federation";
      }
      return false;
    },
    searchPromoCodesThisProduct() {
      let array = [];
      let d = new Date();
      let m = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      let day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
      let formatDate = new Date(`${d.getFullYear()}-${m}-${day}`);

      this.responcePromoCodes.forEach((el) => {
        if (
          el.is_active &&
          new Date(el.date_start).getTime() <= formatDate.getTime() &&
          new Date(el.date_end).getTime() >= formatDate.getTime()
        ) {
          el.promo_code_c.forEach((prod) => {
            if (this.$route.params.id == prod.course) {
              array.push({
                code: el.code,
                discount: el.discount,
              });
            }
          });
        }
      });
      return array;
    },
    priceDiscount() {
      let prc =
        Number((this.product.price / 100) * this.product.discount) +
        Number(this.product.price);
      if (this.discount) {
        prc = this.product.price - (this.product.price / 100) * this.discount;
      }
      if (!isNaN(prc) && this.useCashback && this.cashback != 0) {
        const MDL = 20.75;
        const RON = 4.93;
        const EUR = Number(
          (this.cashback / (this.currency == "RON" ? RON : MDL)).toFixed(2)
        ); // конвертация из бонусов в евро

        let total = prc - EUR;
        this.newCashback =
          (total < 0 ? Math.abs(total) : 0) *
          (this.currency == "RON" ? RON : MDL); // новое кол во бонусов после покупки
        this.newCashback = Number(this.newCashback.toFixed(2));
        prc = total < 0 ? 0 : total;
        if (this.newCashback == 0) {
          this.spentBonuses = this.cashback; // потраченно всё кол во бонусов
        } else {
          this.spentBonuses = (
            Number(this.cashback) - this.newCashback
          ).toFixed(2); // потраченное кол во бонусов
        }
      } else {
        this.spentBonuses = "0"; // бонусы не использовал
      }

      return Math.trunc(prc); // возвращает целую часть числа путём удаления всех дробных знаков.
    },
    paymentSystem() {
      if (this.checkCounryRo) {
        return "https://secure.euplatesc.ro/tdsprocess/tranzactd.php";
      }
      return "https://paynet.md/acquiring/setecom";
    },
    currency() {
      return this.user.profile?.profile?.city == "Romania" ? "RON" : "MDL";
    },
    isAvailable() {
      const profileType = this.user.profile?.profile?.type;
      const key =
        profileType == "doctor"
          ? "doctor"
          : profileType == "cosmetology"
          ? "cosmetolog"
          : "other";
      return this.product[key];
    },
  },
  watch: {
    async lang() {
      await this.changeProdLang();
    },
    "modalFormaData.phone": function(val, old) {
      if (/\D/.test(val)) {
        this.modalFormaData.phone = old;
      }
    },
    "modalFormaData.name": function(val, old) {
      if (/\d/.test(val)) {
        this.modalFormaData.name = old;
      }
    },
    async showModalBuyCourse() {
      await this.getPromoCodes();
      this.cashback = await this.checkBonus();
    },
    promoCode(val, old) {
      this.discount = this.watchDiscount(val);
    },
  },
  async created() {
    const response = await fetch(
      `${domain}/${this.lang}/courses/course/${this.$route.params.id}/`
    );

    //this.course = this.getCourse(this.$route.params.id);

    if (!response.ok) {
      this.$router.push("/").catch(() => {});
    } else {
      const data = await response.json();
      this.product = data;
      this.courses[0].title = this.product.name;
      this.courses[0].description =
        Math.trunc(
          Number((this.product.price / 100) * this.product.discount) +
            Number(this.product.price)
        ) + " €";
      this.courses[0].src = this.product.preview_video_link;
    }
  },
  mounted() {
    this.modalFormaData.select = this.selectLang.modalBuyCourse.buy;
    setTimeout(() => {
      if (this.courses[0].show != 0) {
        this.showPreviewVideo();
      }
    }, 3000);
  },
  methods: {
    googleEventCoursePurchase() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Curs_Purchase_Complete",
      });
    },
    googleEventCourseInitiated() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Purchase_Initiated",
      });
    },
    closeModalBuyCurse(e) {
      if (
        e.target.classList[0] == "buy__course__wrp" ||
        e.target.classList[0] == "close__btn" ||
        e.target.classList[0] == "cls"
      ) {
        this.showModalBuyCourse = false;
      }
    },
    async paymentHistory(payment_code) {
      try {
        const request = await fetch(`${domain}/accounts/payment-history/`, {
          method: "POST",
          headers: {
            Authorization: `Token ${this.user.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user: this.user.userID,
            title: `${this.product.name} #${payment_code}`,
            descriptions: "Lorem desc",
            payment_code: payment_code,
            amount: `${this.priceDiscount} €`,
            confirmed: this.priceDiscount == 0,
            paid_by_card: this.priceDiscount != 0,
            country: this.user.country?.country,
            type: "course",
            // payment_system_name: this.checkCounryRo ? 'euplatesc' : 'paynet',
            payment_system_name: "paynet",
            course_id: this.$route.params.id,
            spent_bonuses: this.spentBonuses,
            use_bonuses: this.useCashback,
            new_users_bonuses: this.newCashback,
            promo_code: this.discount != 0 ? this.promoCode : "",
            utm_tags: this.$store.getters["utmQuery/strUtm"],
          }),
        });
        return request.ok;
      } catch (er) {
        console.error(er);
      }
    },
    async sendData() {
      if (!this.validatePhone) {
        this.modalFormaData.phone = "";
        this.$refs.PayModalPhone.classList = "warning";
      }
      if (!this.validateName) {
        this.modalFormaData.name = "";
        this.$refs.PayModalName.classList = "warning";
      }

      if (
        this.validateName &&
        this.validatePhone &&
        this.modalFormaData.accept &&
        this.modalFormaData.accept2 &&
        this.modalFormaData.accept3
      ) {
        this.spiner = true;

        function addInput(tag, name, value) {
          let elem = document.createElement(tag);
          elem.setAttribute("name", name);
          elem.setAttribute("value", value);
          elem.setAttribute("type", "hidden");
          return elem;
        }
        let sicretKey = "F0321E5A-876C-4AE5-933A-061FC44FBBF3"; // test '47DB687B-6C10-40C1-8522-C9B404F6D71D' //real F0321E5A-876C-4AE5-933A-061FC44FBBF3
        let date = new Date(); // toISOString()
        let month = date.getMonth() + 1;
        let dd = date.getDate();
        let mm = date.getMinutes();
        // let hh = date.getHours() + 1;
        let hh = 23;

        let dateFormat = `${date.getFullYear()}-${
          month >= 10 ? month : "0" + month
        }-${dd >= 10 ? dd : "0" + dd}T${hh >= 10 ? hh : "0" + hh}:${
          mm >= 10 ? mm : "0" + mm
        }:00`;

        let expire_date = dateFormat; // date format 2021-03-08T12:00:00
        let external_id = date.getTime(); //

        let customer_name = this.modalFormaData.name;
        let customer_phone = this.modalFormaData.phone;
        let customer_code =
          "12345678-" + this.$route.params.id + "-" + this.user.userID;
        let merchant = "446466"; // test 631502 //real 446466
        let price = this.priceDiscount;

        let ammount = (price * 100).toString();
        let currency = "978"; //498 MDL, 978 EUR, 840 $
        let pay_method = "lica.doctor";
        let pay_desc = "оплата через Paynet";

        let prep_string =
          currency +
          customer_code +
          customer_name +
          expire_date +
          external_id +
          merchant +
          ammount +
          pay_method +
          pay_desc +
          sicretKey;

        const signature = crypto
          .createHash("md5")
          .update(utf8.encode(prep_string), "binary")
          .digest("base64");

        this.$refs.formWrapp.appendChild(
          addInput("input", "ExternalID", external_id)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "Currency", currency)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "Merchant", merchant)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "Customer[Code]", customer_code)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "Customer[Name]", customer_name)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "Customer.Address", "")
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "Customer[NameFirst]", customer_name)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "Customer.PhoneNumber", customer_phone)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "Customer.Country.", "Moldova")
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "Customer.City", "Chisinau")
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "ExpiryDate", expire_date)
        );
        this.$refs.formWrapp.appendChild(
          addInput(
            "input",
            "LinkUrlSuccess",
            "http://lica.doctor/checkout-success"
          )
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "LinkUrlCancel", "http://lica.doctor/")
        );
        this.$refs.formWrapp.appendChild(addInput("input", "Lang", this.lang));
        this.$refs.formWrapp.appendChild(
          addInput("input", "SaleAreaCode", "http_lica_doctor")
        );
        // this.$refs.formWrapp.appendChild(addInput("input", "SignVersion", 'v05'));

        this.$refs.formWrapp.appendChild(
          addInput("input", "Services[0][Name]", pay_method)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "Services[0][Amount]", ammount)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "Services[0][Description]", pay_desc)
        );
        this.$refs.formWrapp.appendChild(
          addInput(
            "input",
            "Services[0][Products][1][GroupId]",
            this.$route.params.id
          )
        );

        this.$refs.formWrapp.appendChild(
          addInput("input", "Signature", signature)
        );

        const ok = await this.paymentHistory(external_id);
        if (ok) {
          if (this.priceDiscount != 0) {
            this.$refs.formByCourse.submit();
          } else {
            await this.updateCashback(this.newCashback);
            this.$router.push({
              name: "checkout-success",
              params: { lang: this.lang },
            });
            this.googleEventCoursePurchase();
          }
        }

        // this.showModalBuyCourse = false;
      }
    },
    async sendDataRo() {
      if (!this.validatePhone) {
        this.modalFormaData.phone = "";
        this.$refs.PayModalPhone.classList = "warning";
      }
      if (!this.validateName) {
        this.modalFormaData.name = "";
        this.$refs.PayModalName.classList = "warning";
      }

      if (
        this.validateName &&
        this.validatePhone &&
        this.modalFormaData.accept &&
        this.modalFormaData.accept2 &&
        this.modalFormaData.accept3
      ) {
        this.spiner = true;

        function addInput(tag, name, value) {
          let elem = document.createElement(tag);
          elem.setAttribute("name", name);
          elem.setAttribute("value", value);
          elem.setAttribute("type", "hidden");
          return elem;
        }
        function getRandomInt() {
          let min = Math.ceil(1);
          let max = Math.floor(9);
          return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }
        let d = new Date();
        let amount =
          Number(this.priceDiscount) % 1 === 0
            ? this.priceDiscount + ".00"
            : this.priceDiscount;
        let curr = "EUR";
        let invoice_id = `${getRandomInt()}${getRandomInt()}${getRandomInt()}${getRandomInt()}${getRandomInt()}${getRandomInt()}`; //Order number
        let order_desc = "Course lica doctor";
        let merch_id = "44840995506"; //ID Comerciant (MID):   44840995506 // test testaccount
        let timestamp = `${d.getFullYear()}${d.getMonth()}${d.getDate()}${d.getMinutes()}${d.getSeconds()}`;
        let nonce = crypto.randomBytes(16).toString("base64");
        let hmac = "";

        let data = {
          amount: amount,
          curr: curr,
          invoice_id: invoice_id,
          order_desc: order_desc,
          merch_id: merch_id,
          timestamp: timestamp,
          nonce: nonce,
        };

        let datakeys = Object.keys(data);

        for (let i = 0; i < datakeys.length; i++) {
          if (data[datakeys[i]].length == 0) {
            hmac += "-";
          } else {
            hmac += data[datakeys[i]].length + data[datakeys[i]];
          }
        }

        let binKey = new Buffer(
          "5C335286A4FF60888AF0184CADCA6024A3902F9C",
          "hex"
        ); //key Cheie  5C335286A4FF60888AF0184CADCA6024A3902F9C // test 00112233445566778899AABBCCDDEEFF
        let hmacx = crypto
          .createHmac("md5", binKey)
          .update(hmac, "utf8")
          .digest("hex");
        data["fp_hash"] = hmacx;

        this.$refs.formWrapp.appendChild(addInput("input", "amount", amount));
        this.$refs.formWrapp.appendChild(addInput("input", "curr", curr));
        this.$refs.formWrapp.appendChild(
          addInput("input", "invoice_id", invoice_id)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "order_desc", order_desc)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "merch_id", merch_id)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "timestamp", timestamp)
        );
        this.$refs.formWrapp.appendChild(addInput("input", "nonce", nonce));
        this.$refs.formWrapp.appendChild(addInput("input", "fp_hash", hmacx));

        this.$refs.formWrapp.appendChild(
          addInput(
            "input",
            "ExtraData[silenturl]",
            `${domain}/courses/access_list/payment_notification_euplatesc/`
          )
        ); //запрос POST от сервера к серверу)
        this.$refs.formWrapp.appendChild(
          addInput(
            "input",
            "ExtraData[successurl]",
            `https://lica.doctor/${this.lang}/checkout-success/`
          )
        );
        this.$refs.formWrapp.appendChild(
          addInput(
            "input",
            "ExtraData[failedurl]",
            `https://lica.doctor/${this.lang}/`
          )
        );
        this.$refs.formWrapp.appendChild(
          addInput(
            "input",
            "ExtraData[backtosite]",
            `https://lica.doctor/${this.lang}/`
          )
        );

        this.$refs.formWrapp.appendChild(
          addInput("input", "fname", this.modalFormaData.name)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "phone", this.modalFormaData.phone)
        );
        this.$refs.formWrapp.appendChild(
          addInput("input", "email", this.user.userLogin)
        );

        const ok = await this.paymentHistory(invoice_id);
        if (ok) {
          this.$refs.formByCourse.submit();
          this.googleEventCoursePurchase();
        }
      }
    },
    async changeProdLang() {
      const response = await fetch(
        `${domain}/${this.lang}/courses/course/${this.$route.params.id}/`
      );
      if (response.ok) {
        const data = await response.json();
        this.product = data;
        this.courses[0].src = this.product.preview_video_link;
      }
    },
    async getPromoCodes() {
      try {
        let request = await fetch(`${domain}/courses/promo-code-course/`, {
          headers: {
            Authorization: `Token ${this.user.token}`,
            "Content-Type": "application/json",
          },
        });
        if (!request.ok) {
          throw request;
        }
        let responce = await request.json();
        this.responcePromoCodes = responce;
      } catch (er) {
        console.error(er);
      }
    },
    watchDiscount(code) {
      for (let obj of this.searchPromoCodesThisProduct) {
        if (obj.code == code) {
          return obj.discount;
        }
      }
      return 0;
    },
    async checkBonus() {
      const request = await fetch(`${domain}/accounts/check_bonuses/`, {
        headers: {
          Authorization: `Token ${this.user.token}`,
        },
      })
        .then(async (el) => {
          if (!el.ok) {
            throw el;
          }
          let responce = await el.json();
          return responce;
        })
        .catch((ex) => {
          console.error(ex);
        });
      return request.money;
    },
    async updateCashback(cashback) {
      const req = await fetch(
        `${domain}/accounts/user_profile/${this.user.profile?.profile?.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            money: cashback,
          }),
        }
      );
      if (!req.ok) {
        throw req;
      }
    },
    showPreviewVideo(muted = 1) {
      this.courses[0].show = 0;
      this.$nextTick(() => {
        let iframe = document.querySelector("iframe");
        if (iframe) {
          iframe.setAttribute("src", iframe.src + `&autoplay=1&muted=${muted}`);
        }
      });
    },
    buyCourse() {
      if (this.isAvailable) {
        this.showModalBuyCourse = !this.showModalBuyCourse;
      }
    },
  },
};
</script>

<style lang="scss">
body.night {
  .course-vantages {
    &__wrap {
      .vantage-item {
        .vantage-item__descr {
          p {
            color: white;
          }
        }
      }
    }
  }
  .content-container {
    .course-contents {
      &__header {
        h2 {
          color: white;
        }
      }
      .contents-items {
        .item {
          span {
            color: white;
          }
        }
      }
      &__accordions {
        .accordion-item {
          .title {
            color: white;
          }
        }
      }
    }
    .course-about {
      &__item {
        .description {
          p {
            color: white;
          }
        }
      }
    }
    .course-details {
      &__descr {
        p {
          color: white;
        }
      }
    }
    .course-cost {
      &__title {
        strong {
          color: white;
        }
      }
    }
  }
}
.page__breadcrumbs {
  .course-prices__buttons {
    .buy {
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  @media (max-width: 576px) {
    .links .options {
      display: none;
    }
  }

  .course__row {
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }

    .left {
      width: 53%;
      margin: 0;
      padding-right: 155px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 1200px) {
        padding-right: 100px;
      }

      @media (max-width: 992px) {
        padding-right: 50px;
      }

      @media (max-width: 768px) {
        width: 100%;
        padding-right: 0px;
      }
    }

    .right {
      width: 47%;

      @media (max-width: 768px) {
        width: 100%;
      }

      @media (max-width: 576px) {
        padding-bottom: 30px;
      }
    }
  }
}

.course__row {
  .course-vantages {
    &__title {
      font-size: 40px;
      font-weight: 600;
      line-height: 72px;
      margin-bottom: 56px;

      @media (max-width: 1200px) {
        font-size: 35px;
      }

      @media (max-width: 992px) {
        margin-bottom: 30px;
        line-height: 50px;
      }

      @media (max-width: 576px) {
        font-size: 25px;
        line-height: 72px;
      }

      span {
        color: #0066ff;
      }
    }

    &__wrap {
      .vantage-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 34px;

        &__icon {
          margin-right: 15px;
        }

        &__descr p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #000001;
          margin: 0;
          text-align: left;

          @media (max-width: 576px) {
            font-size: 15px;
          }
        }
      }
    }
  }

  .course-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 18px;

    h1 {
      font-size: 40px;
      font-weight: 600;
      line-height: 56px;
      color: #000001;

      @media (max-width: 1280px) {
        font-size: 34px;
        line-height: 48px;
      }

      @media (max-width: 576px) {
        font-size: 23px;
        line-height: 36px;
        text-align: center;
      }
    }
    &__discount {
      display: flex;
      margin-left: auto;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 84px;
      height: 84px;
      background: #eb236b;
      border-radius: 12px 64px 64px 64px;

      @media (max-width: 576px) {
        display: none;
      }

      span {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        color: #fff;
      }
    }
  }

  .course-preview {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    min-height: 350px;
    cursor: pointer;

    @media (max-width: 576px) {
      margin-bottom: 20px;
    }

    &:before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      background: #000;
      z-index: 10;
      opacity: 0.3;
      border-radius: 12px;
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 10;
      top: 14px;
      right: 10px;
      width: 84px;
      height: 84px;
      background: #eb236b;
      border-radius: 12px 64px 64px 64px;

      span {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        color: #fff;
      }

      &.discount {
        left: 10px;
        display: none;

        @media (max-width: 576px) {
          display: flex;
        }
      }
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 12px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__descr {
      position: relative;
      z-index: 100;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 80%;
      margin: 0 auto;

      .button-play {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 64px;
        margin: 27px 0;
      }

      .description {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 2px;
        color: #fff;
        text-transform: uppercase;

        @media (max-width: 1200px) {
          font-size: 15px;
          line-height: 24px;
        }

        @media (max-width: 576px) {
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
  }
}

.course-props {
  @media (max-width: 576px) {
    padding-bottom: 20px;
  }

  &__tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    @media (max-width: 1200px) {
      padding-right: 0px;
      padding-bottom: 15px;
    }

    @media (max-width: 768px) {
      padding-bottom: 0px;
    }

    a {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #000001;
      padding: 5px 16px;
      display: inline-block;
      background: #fff;
      border-radius: 24px;
      margin-right: 14px;
      margin-bottom: 14px;
    }
  }
  .duration {
    display: flex;
    align-items: center;
    margin-right: 14px;
    margin-bottom: 14px;

    span {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      padding-left: 10px;
    }
  }
}

.course-prices {
  display: flex;
  align-items: center;

  @media (max-width: 576px) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__old {
    position: relative;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #515076;
    margin-right: 30px;

    @media (max-width: 1200px) {
      font-size: 20px;
    }

    @media (max-width: 576px) {
      margin-left: 20px;
    }

    &::before {
      content: "";
      position: absolute;
      height: 1px;
      background: #515076;
      left: 0;
      right: 0;
      top: 50%;
    }
  }
  &__curr {
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    color: #eb236b;

    @media (max-width: 1200px) {
      font-size: 31px;
    }
  }
  &__buttons {
    margin-left: auto;
    margin-right: 10px;

    @media (max-width: 576px) {
      width: 100%;
      margin: 0;
      text-align: center;
    }

    .buy {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #fff;
      background: #eb236b;
      padding: 34px 54px;
      border-radius: 96px;
      display: block;
      transition: 0.3s;

      @media (max-width: 1200px) {
        font-size: 18px;
        padding: 22px 42px;
      }

      @media (max-width: 576px) {
        font-size: 16px;
        line-height: 24px;
        padding: 20px 42px;
      }

      &:hover {
        background: #c71857;
      }
    }
  }
}

.content-container {
  max-width: 1728px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.course-contents {
  max-width: 1530px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 150px;

  @media (max-width: 1200px) {
    padding-bottom: 70px;
    padding-left: 35px;
    padding-right: 35px;
  }

  @media (max-width: 768px) {
    padding-bottom: 40px;
    padding-left: 0px;
    padding-right: 0px;
  }

  &__header {
    display: flex;
    flex-direction: revert;
    align-items: center;

    @media (max-width: 576px) {
      flex-direction: column;
    }

    h2 {
      width: 50%;
      font-weight: 600;
      font-size: 55px;
      line-height: 72px;
      color: #000001;

      @media (max-width: 1200px) {
        font-size: 40px;
      }

      @media (max-width: 992px) {
        line-height: 52px;
      }

      @media (max-width: 768px) {
        line-height: 100%;
      }

      @media (max-width: 576px) {
        width: 100%;
        text-align: center;
        font-size: 30px;
        line-height: 40px;
      }

      span {
        color: #14685d;
      }
    }

    .contents-items {
      width: 50%;
      display: flex;

      @media (max-width: 576px) {
        width: 100%;
        flex-direction: column;
      }

      .item {
        margin-right: 50px;

        @media (max-width: 992px) {
          margin-right: 40px;
        }

        @media (max-width: 768px) {
          margin-right: 35px;
        }

        @media (max-width: 576px) {
          padding-bottom: 20px;
        }

        .num {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #fff;
          background: #000001;
          border-radius: 36px;
          display: inline-block;
          padding: 6px 24px;
          margin-right: 10px;
        }

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000001;

          @media (max-width: 992px) {
            display: block;
          }
        }
      }
    }
  }

  &__accordions {
    .accordion-item {
      margin-bottom: 10px;
      border-bottom: 1px solid #adadbd;
      cursor: pointer;

      .title {
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        color: #000001;
        display: flex;
        align-items: center;
        padding: 24px 0;

        @media (max-width: 576px) {
          font-size: 16px;
          line-height: 24px;
          padding: 22px 0;
        }
      }

      .btn {
        height: 36px;
        width: 36px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: 10px;
        border: 1px solid #000001;
        background: #fff;
        background-image: url("/img/content-plus.svg");
        background-repeat: no-repeat;
        background-position: center;
        transition: 0.2s;
        cursor: pointer;
      }

      &.active .btn {
        background: #000001;
        background-image: url("/img/content-close.svg");
        background-repeat: no-repeat;
        background-position: center;
      }

      .description {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.7s;
        padding-left: 15px;
        padding-right: 15px;
        color: #515076;

        @media (max-width: 576px) {
          font-size: 14px;
          line-height: 22px;
        }
      }

      &.active .description {
        max-height: 100vh;
        padding-bottom: 15px;
        overflow-y: scroll;
      }
    }
  }
}

.course-about {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 70px;

  @media (max-width: 1200px) {
    padding-bottom: 50px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 40px;
  }

  @media (max-width: 576px) {
    display: none;
  }

  &__item {
    width: 26%;
    padding: 0 20px;

    @media (max-width: 992px) {
      width: 33%;
    }

    @media (max-width: 768px) {
      width: 100%;
      padding-bottom: 20px;
    }

    .title {
      font-weight: 600;
      font-size: 19px;
      line-height: 30px;
      color: #eb236b;
      min-height: 60px;
      margin-bottom: 15px;
      text-transform: uppercase;

      @media (max-width: 1200px) {
        font-size: 19px;
      }

      @media (max-width: 768px) {
        min-height: auto;
      }
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #141418;

      p {
        margin: 0 0 20px 0;
      }
    }
  }
}

.course-details {
  display: flex;
  align-items: center;
  padding-bottom: 90px;

  @media (max-width: 1200px) {
    padding-bottom: 70px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 30px;
  }

  &__image {
    width: 43.6%;
    height: 400px;
    padding-right: 48px;
    overflow: hidden;
    border-radius: 12px;

    @media (max-width: 768px) {
      width: 100%;
      padding-right: 0px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }

  &__descr {
    max-width: 50%;

    @media (max-width: 768px) {
      max-width: 90%;
      text-align: center;
    }

    @media (max-width: 576px) {
      max-width: 100%;
      text-align: left;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #000001;

      @media (max-width: 576px) {
        font-size: 17px;
        line-height: 30px;
      }
    }
  }
}

.course-cost {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 70px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  &__title {
    width: 35%;

    @media (max-width: 1366px) {
      width: 45%;
    }

    @media (max-width: 1200px) {
      width: 50%;
    }

    @media (max-width: 992px) {
      width: 48%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    @media (max-width: 576px) {
      width: 100%;
      padding: 0 20px;
    }

    strong {
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      color: #000001;

      @media (max-width: 1200px) {
        font-size: 40px;
      }

      @media (max-width: 576px) {
        font-size: 24px;
        line-height: 36px;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      text-transform: uppercase;
      color: #eb236b;

      @media (max-width: 1200px) {
        font-size: 19px;
      }

      @media (max-width: 576px) {
        font-size: 14px;
        line-height: 30px;
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
  }

  .course-prices {
    width: 30%;

    @media (max-width: 1366px) {
      width: 45%;
    }

    @media (max-width: 992px) {
      width: 50%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    @media (max-width: 576px) {
      width: 100%;
    }
  }
}

.page__breadcrumbs {
  .course__row {
    .btns {
      .buy {
        &.disabled {
          background: #757575;
          cursor: not-allowed;
        }
      }
    }
  }
}

.advantage-p {
  font-weight: 500;
  font-size: 18px;
}

.page__breadcrumbs .course__row .left .btns a {
  white-space: nowrap;
  font-size: 16px !important;
}

.buy__course form .btm label input::after {
  top: -7px !important;
}
.spiner_course {
  width: 100%;
}
.buyForm input.warning::-webkit-input-placeholder {
  color: red !important;
}
.buyForm input.warning::-moz-placeholder {
  color: red !important;
}

.buy__course__wrp {
  z-index: 1000;
  .policy_wrapp {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding-left: 5px;
    label {
      margin-bottom: 5px;
      width: 100% !important;
    }
  }
}

.inp__row {
  .promocode {
    width: 100% !important;
  }
}

.buyForm {
  .promo {
    color: #eb236b;
  }
  .promo_green {
    color: #14685d;
  }
}

.buy__course__wrp {
  .custom_checkbox {
    margin-bottom: 15px;
    strong {
      margin-bottom: 0;
      span {
        font-size: 13px;
        color: #14685d;
      }
    }
    /* для элемента input c type="checkbox" */
    .custom-checkbox {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }
    /* для элемента label, связанного с .custom-checkbox */
    .custom-checkbox + label {
      display: inline-flex;
      align-items: center;
      user-select: none;
      cursor: pointer;
    }
    /* создание в label псевдоэлемента before со следующими стилями */
    .custom-checkbox + label::before {
      content: "";
      display: inline-block;
      width: 1.3em;
      height: 1.3em;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #14685d;
      border-radius: 0.25em;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
    /* стили при наведении курсора на checkbox */
    .custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
      border-color: #14685d;
    }

    /* стили для активного чекбокса (при нажатии на него) */
    .custom-checkbox:not(:disabled):active + label::before {
      background-color: #14685d75;
      border-color: #14685d75;
    }

    /* стили для чекбокса, находящегося в фокусе */
    .custom-checkbox:focus + label::before {
      box-shadow: 0 0 0 0.2rem #14685d80;
    }

    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    .custom-checkbox:focus:not(:checked) + label::before {
      border-color: #14685d;
    }

    /* стили для чекбокса, находящегося в состоянии checked */
    .custom-checkbox:checked + label::before {
      border-color: #14685d;
      background-color: #14685d;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
      background-position-x: 5px;
    }
  }
}

.page__breadcrumbs {
  position: relative;
  justify-content: center;
  .options {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 30px;
    cursor: pointer;
    span {
      font-weight: 500;
      font-size: 14px;
      color: #6b6b78;
      margin-left: 5px;
    }
    &:hover {
      .arrow-img {
        transform: rotate(0deg);
      }
    }
    @media (max-width: 430px) {
      display: none;
    }
  }
  .arrow-img {
    transform: rotate(180deg);
    transition: 0.5s;
    width: 25%;
  }
}

@media (max-width: 1200px) and (min-width: 430px) {
  .page__breadcrumbs .course__row {
    padding: 0 !important;
  }
  .page__breadcrumbs .course__row .left .btns a {
    padding: 20px 15px !important;
    line-height: 25px !important;
  }

  .page__breadcrumbs .course__row .left .btm .tags {
    margin-right: 20px !important;
  }
  .course__content {
    padding: 100px 50px !important;
  }
  .about__details .photo__cnt .img {
    min-height: 300px;
  }
}
@media (max-width: 1024px) and (min-width: 430px) {
  .page__breadcrumbs {
    padding: 15px;
  }
  .page__breadcrumbs .course__row .left .title .sale {
    display: none;
  }
  .course__cnt .about__row strong {
    line-height: unset;
    letter-spacing: unset;
  }
  .course__cnt .about__row .col {
    width: calc(35% - 24px);
  }
  .course__cnt .about__details {
    margin-bottom: 100px;
  }
  .course__cnt .abilities .card {
    width: 50%;
  }
  .course__content .top .right .btm {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .card {
      margin-bottom: 13px;
    }
  }
}
@media (max-width: 430px) {
  .course__cnt .about__row strong.title {
    color: #eb236b !important;
  }
  .page__breadcrumbs .course__row .left .title strong {
    height: 35px;
    // overflow: hidden;
  }
  .course__cnt .cost strong {
    width: 100%;
  }
  .course__cnt .cost {
    padding: 0 16px;
    margin-top: 0px;
    margin-bottom: 96px;
    flex-wrap: wrap;
    position: relative;

    strong {
      font-size: 24px;
      line-height: 36px;
      margin: 24px 0;
    }
    .right {
      width: 100%;
    }
    .prices {
      position: absolute;
      top: 0;
      right: 16px;
      display: flex;
      flex-direction: column;
      margin: 0;
      .current {
        order: 2;
        margin: 0;
        font-size: 24px;
        line-height: 36px;
      }
      .old {
        order: 1;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .register__btn {
      width: 100%;
      line-height: 64px;
      text-align: center;
    }
  }

  .about__details .photo__cnt .img {
    min-height: 200px;
  }

  .page__breadcrumbs .course__row .left .btns {
    margin-top: 50px;
  }

  .btn_buy {
    display: flex;
    width: 200px;
    height: 75px;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
  .course__cnt .cost .right {
    width: unset;
  }

  .page__breadcrumbs .course__row .left .title {
    width: 100%;
    justify-content: center;
    margin-bottom: 465px !important;
  }
}

@media (min-width: 430px) {
  .course__content .content__col .card p {
    font-size: 18px !important;
  }
  .course__cnt .about__details .txt {
    font-size: 16px !important;
  }
  .course__cnt .about__row {
    justify-content: space-around;
  }
  .course__cnt .about__row span {
    font-size: 16px;
    font-weight: 500;
  }
  .course__cnt .about__row {
    & strong.title {
      font-size: 19px;
      color: #eb236b !important;
      letter-spacing: 1.5px;
    }
    & strong {
      font-size: 15px;
      color: black;
      letter-spacing: 0px;
    }
  }
}
.about__row {
  .inner-p {
    strong {
      font-size: 16px;
      text-transform: none;
      font-weight: bolder;
      color: black;
      letter-spacing: normal;
    }
  }
}
.page__breadcrumbs .course__row .left .btm .tags {
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    a {
      margin-bottom: 10px;
      padding: 5px 10px;
    }
  }
}
</style>
